/**
 * Name used to store party_id
 */
export const PARTY_ID_KEY = '_dvp';

/**
 * Number of days until party id will expire (2 years)
 */
export const PARTY_ID_TTL_DAYS = 365 * 2;

/**
 * Name used to store sessionId value
 */
export const SESSION_ID_KEY = '_dvs';

/**
 * Number of days until sessionId will expire (30 minutes = 30 / (24 * 60) days)
 */
export const SESSION_ID_TTL_DAYS = 1 / 48;

/**
 * Name used to store nmoId value
 */
export const NMO_ID_KEY = 'cs_fpid';

/**
 * Number of days until nmoId will expire (3 months = 3 x 30 days)
 */
export const NMO_ID_TTL_DAYS = 3 * 30;
