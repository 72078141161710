/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Class for serializing values using a minimum encoding.
 *
 * The encoding is designed to be short, url-friendly, and capable of representing anything
 * that JSON could.
 *
 * Objects are encoded as a series of records. The format of a record is:
 *  - Character: indicates the type of record.
 *  - Optional: If encoding an object, the name of the property which the record encodes.
 *  - Optional: Record-specific payload.
 *
 * Property names are presented as is, terminated with a "!". Within the string itself, any
 * occurrences of "!" or "~" are prefixed with "~".
 *
 * The record types are:
 * <li>'s': for a string, with a payload of the string itself encoded in the same was as
 *          property names (described above).</li>
 * <li>'t': for a boolean, with a value of true.</li>
 * <li>'f': for a boolean, with a value of false.</li>
 * <li>'n': for null.</li>
 * <li>'d': for a number, with a payload of the the base36-encoded number terminated with
 *          a "!".</li>
 * <li>'j': for a number, with a payload containing the JSON-encoded number terminated with
 *          a "!".</li>
 * <li>'(': a special record indicating the start of an object.</li>
 * <li>')': a special record indicating the end of an object.</li>
 * <li>'a': a special record indicating the start of an array.</li>
 * <li>'.': a special record indicating the end of an array.</li>
 *
 * @constructor
 * @final
 */
class Mincoder {
  /**
   * Regular expression for escaping strings while serializing.
   * @const
   * @type {RegExp}
   */
  private readonly stringEscapingRegex = /[~!]/g;

  /**
   * Buffer containing the records encoded so far.
   * @protected
   * @type {string}
   */
  private buffer = '';
  /**
   * Field containing the name of the property to which the next
   * record will be assigned. Used when encoding objects.
   * @type {?string}
   */
  private pendingFieldName: string | null = null;
  /**
   * Field containing the name of the array property to which the next
   * items will be assigned. Used when encoding array.
   * @type {?string}
   */
  private pendingArrayFieldName: string | null = null;

  /**
   * Start encoding an object.
   * @private
   */
  private startObject(): void {
    this.addRecord('(');
  }

  /**
   * Finish encoding an object.
   * @private
   */
  private endObject(): void {
    this.pendingFieldName = null;
    this.addRecord(')');
  }

  /**
   * Start encoding an array.
   * @private
   */
  private startArray(): void {
    this.pendingArrayFieldName = this.pendingFieldName;
    this.pendingFieldName = null;
  }
  /**
   * Finished encoding an array.
   * @private
   */
  private endArray(previousBufferLength: number): void {
    if (this.buffer.length > previousBufferLength) {
      this.addRecord('.');
    } else {
      this.pendingArrayFieldName = null;
    }
  }
  /**
   * Set the name of the property that the next record will be assigned
   * to.
   * @private
   * @param {string} fieldName the property name.
   */
  private setNextFieldName(fieldName: string): void {
    this.pendingFieldName = fieldName;
  }
  /**
   * Add a record to the buffer.
   * @private
   * @param {string} recordType the type of the record.
   * @param {string} payload    the (optional) payload for this record.
   */
  private addRecord(recordType: string, payload?: string): void {
    if (null !== this.pendingArrayFieldName) {
      this.buffer += 'a';
      this.buffer += this.escapeString(this.pendingArrayFieldName);
      this.buffer += '!';
      this.pendingFieldName = null;
      this.pendingArrayFieldName = null;
    }
    this.buffer += recordType;
    if (null !== this.pendingFieldName) {
      this.buffer += this.escapeString(this.pendingFieldName);
      this.buffer += '!';
      this.pendingFieldName = null;
    }
    if (payload) {
      this.buffer += payload;
    }
  }

  /**
   * Encode a variable-length string value.
   * @param {string} s the string to encode.
   */
  private escapeString(s: string): string {
    return s.replace(this.stringEscapingRegex, '~$&');
  }

  /**
   * Encode a string.
   * @private
   * @param {string} s the string to encode as a record.
   */
  private encodeString(s: string): void {
    this.addRecord('s', this.escapeString(s) + '!');
  }

  /**
   * Encode a number.
   * @private
   * @param {number} n the number to encode as a record.
   */
  private encodeNumber(n: number): void {
    if (isFinite(n)) {
      // A 'd' record is only allowed to encode integers.
      const dEncoding = n === Math.floor(n) ? n.toString(36) : null,
        jEncoding1 = n.toExponential(),
        jEncoding2 = String(n);
      // We prefer a 'd' record to 'j' even if equal length because they're
      // more efficient to process on the server.
      if (
        null !== dEncoding &&
        dEncoding.length <= jEncoding1.length &&
        dEncoding.length <= jEncoding2.length
      ) {
        this.addRecord('d', dEncoding + '!');
      } else {
        this.addRecord(
          'j',
          (jEncoding1.length < jEncoding2.length ? jEncoding1 : jEncoding2) +
            '!'
        );
      }
    } else {
      this.encode(null, false);
    }
  }

  /**
   * Encode a boolean.
   * @private
   * @param b {boolean} b the boolean to encode as a record.
   */
  private encodeBoolean(b: boolean): void {
    this.addRecord(b ? 't' : 'f');
  }

  /**
   * Encode a null.
   * @private
   */
  private encodeNull(): void {
    this.addRecord('n');
  }

  /**
   * Encode an array.
   * @private
   * @param {!Array<*>} a the array to encode as a series of records.
   */
  private encodeArray(a: any[]): void {
    const previousBufferLength = this.buffer.length;
    this.startArray();
    for (let i = 0; i < a.length; ++i) {
      this.encode(a[i], true);
    }
    this.endArray(previousBufferLength);
  }
  /**
   * Encode a Date.
   * Dates are encoded as a string, as with JSON.
   * @private
   * @param {!Date} d the date to encode as a record.
   */
  private encodeDate(d: Date): void {
    const rendered = isFinite(d.valueOf())
      ? d.getUTCFullYear() +
        '-' +
        this.pad(2, d.getUTCMonth() + 1) +
        '-' +
        this.pad(2, d.getUTCDate()) +
        'T' +
        this.pad(2, d.getUTCHours()) +
        ':' +
        this.pad(2, d.getUTCMinutes()) +
        ':' +
        this.pad(2, d.getUTCSeconds()) +
        '.' +
        this.pad(3, d.getUTCMilliseconds()) +
        'Z'
      : null;
    this.encode(rendered, false);
  }

  /**
   * Zero-pad a number.
   * @param {number} len the length to pad to.
   * @param {number} n   the number to
   * @returns {string} the number, zero-padded to the required length
   */
  private pad(len: number, n: number): string {
    let result = n.toString();
    while (result.length < len) {
      result = '0' + result;
    }
    return result;
  }

  /**
   * Encode a generic object.
   * @private
   * @param {!Object} o an object to encode.
   */
  private encodeJavaScriptObject(o: any): void {
    // Do not encode object if all properties are undefined
    if (Object.values(o).every(el => typeof el === 'undefined')) {
      return;
    }
    this.startObject();
    for (const k in o) {
      if (Object.prototype.hasOwnProperty.call(o, k)) {
        this.setNextFieldName(k);
        this.encode(o[k], false);
      }
    }
    this.endObject();
  }
  /**
   * Encode an object.
   * Note that arrays, dates and null are all objects.
   * @param {Object} o the object to encode as a series of records.
   * @param {boolean} replaceUndefinedWithNull
   *                  true if undefined values should be replaced with null, or false if they should be elided.
   */
  private encodeObject(o: any, replaceUndefinedWithNull: boolean) {
    if (o === null) {
      this.encodeNull();
    } else if (typeof o.toJSON === 'function') {
      this.encode(o.toJSON(), replaceUndefinedWithNull);
    } else {
      switch (Object.prototype.toString.call(o)) {
        case '[object Array]':
          this.encodeArray(/**@type !Array<*>*/ o);
          break;
        case '[object Date]':
          this.encodeDate(/**@type !Date*/ o);
          break;
        default:
          this.encodeJavaScriptObject(o);
      }
    }
  }
  /**
   * Encode a value as a series of records.
   * @param {*} value the value to encode.
   * @param {boolean} replaceUndefinedWithNull
   *                  true if undefined values should be replaced with null, or false if they should be elided.
   * @return {string} a string that represents the encoded value.
   */
  encode(value: any, replaceUndefinedWithNull: boolean): string | undefined {
    switch (typeof value) {
      case 'string':
        this.encodeString(value);
        break;
      case 'number':
        this.encodeNumber(value);
        break;
      case 'boolean':
        this.encodeBoolean(value);
        break;
      case 'object':
        this.encodeObject(value, replaceUndefinedWithNull);
        break;
      case 'undefined':
        if (replaceUndefinedWithNull) {
          this.encode(null, false);
        }
        break;
      default:
        throw 'Cannot encode of type: ' + typeof value;
    }
    const result = this.buffer;
    return result !== '' ? result : undefined;
  }
}

export const mincode = (value: any): string | undefined =>
  new Mincoder().encode(value, false);
