export const DOMUtils = () => {
  /**
   * Obtain the body element for the current document.
   * This needs to be retrieved on demand: the body element can be
   * replaced in the DOM, and may also be unavailable duringn initial
   * page loading.
   * @returns {?(HTMLElement|Node)}
   */
  const getBodyElement = () =>
    document.body || document.getElementsByTagName('body').item(0);

  /* Some current browser features that we send to Govolte. */
  /**
   * The width of the user screen.
   * @const
   * @type {number}
   */
  const getScreenWidth = (): number => window.screen.availWidth;

  /**
   * The height of the user screen.
   * @const
   * @type {number}
   */
  const getScreenHeight = (): number => window.screen.availHeight;

  /**
   * Query the current width of the browser window.
   * @type {function():?number}
   */
  const getWindowWidth = (): number => {
    return (
      window['innerWidth'] ||
      document.documentElement['clientWidth'] ||
      getBodyElement()['clientWidth'] ||
      document.documentElement['offsetWidth'] ||
      getBodyElement()['offsetWidth']
    );
  };

  /**
   * Query the current height of the browser window.
   * @type {function():?number}
   */
  const getWindowHeight = (): number => {
    return (
      window['innerHeight'] ||
      document.documentElement['clientHeight'] ||
      getBodyElement()['clientHeight'] ||
      document.documentElement['offsetHeight'] ||
      getBodyElement()['offsetHeight']
    );
  };

  /**
   * Query the current screen orientation browser window.
   * @type {function():?string}
   * @remarks
   * The screen orientation api is not supported on all browsers. If the screen orientation
   * api is not supported, we will attempt to use the deprecated window.orientation api (which
   * is supported on iOS Safari). If this is also undefined, we use the screen width and height
   * to determine the orientation (this is the case for Desktop Safari).
   * See here for more info: https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
   */
  const getScreenOrientation = (): string | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const screenAny = screen as any;
    const orientation =
      screen.orientation?.type ||
      screenAny['mozOrientation'] ||
      screenAny['msOrientation'];
    if (orientation) {
      return orientation;
    }
    // Fall back to deprecated window.orientation api
    if (window.orientation !== undefined) {
      switch (window.orientation) {
        case 0:
          return 'portrait-primary';
        case 90:
          return 'landscape-primary';
        case 180:
          return 'portrait-secondary';
        case -90:
          return 'landscape-secondary';
      }
    }
    // Fall back to simple width/height comparison
    return getWindowWidth() > getWindowHeight()
      ? 'landscape-primary'
      : 'portrait-primary';
  };

  /**
   * Obtain the location href for the current document.
   * @returns a string containing the current location href.
   */
  const getLocation = (): string => {
    return window.location.href;
  };

  /**
   * Obtain the referrer for the current document.
   * @returns a string containing the current document referrer, or undefined if `document.referrer` is an empty string.
   */
  const getReferrer = (): string | undefined => {
    return document.referrer || undefined;
  };

  return {
    getScreenWidth,
    getScreenHeight,
    getWindowWidth,
    getWindowHeight,
    getScreenOrientation,
    getLocation,
    getReferrer,
  };
};
