/**
 * https://stackoverflow.com/a/41462752/2930421
 */
export const isLocalStorageAvailable = (): boolean => {
  try {
    const storage = window['localStorage'];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};
