import {StorageInterface, StorageOptions} from './index';
import Cookies from 'js-cookie';

export class CookieStorage implements StorageInterface {
  get(key: string): string | undefined {
    return Cookies.get(key);
  }

  set(key: string, value: string, options?: StorageOptions): void {
    Cookies.set(key, value, {
      expires: options && options.expires,
    });
  }

  remove(key: string) {
    Cookies.remove(key);
  }
}
