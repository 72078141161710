import {EventType} from './eventType';
import {Event} from './event';

export interface EventThrottler {
  /**
   * Checks if an {@link Event} should be throttled (i.e. not sent)
   * @param eventType {@link EventType} of the event
   * @param event {@link Event} object to check
   * @returns `true` if event should be throttled, `false` otherwise
   */
  shouldThrottleEvent(eventType: EventType, event: Event): boolean;
  /**
   * Callback allowing the throttler to update internal state based on a sent event
   * @param eventType {@link EventType} of the event
   * @param event {@link Event} that has been sent
   */
  onSendEvent(eventType: EventType, event: Event): void;
}

/**
 * Applies an array of event throttlers to the given event
 * @param eventType type of the event
 * @param event event object to throttle
 * @returns true if the event should be throttled, false otherwise
 */
export function throttleEvent(
  eventType: EventType,
  event: Event,
  eventThrottlers: EventThrottler[]
): boolean {
  for (const throttler of eventThrottlers) {
    // Return early if any throttler returns true
    if (throttler.shouldThrottleEvent(eventType, event)) {
      // Event should be throttled (i.e. not sent)
      return true;
    }
  }

  // Notify throttlers that the event was not throttled
  eventThrottlers.forEach(throttler => throttler.onSendEvent(eventType, event));

  // Event should not be throttled (i.e. sent)
  return false;
}
