/* eslint-disable @typescript-eslint/no-explicit-any */

const PLUGIN_TAG = 'npo-tag-web';

export function basicLogMessage(): string {
  return `${new Date().toISOString()} ${PLUGIN_TAG}`;
}

export function log(tag: string, message?: any, ...optionalParams: any[]) {
  if (logLevel > LogLevel.INFO) {
    return;
  }
  console.log(`${basicLogMessage()} ${tag}: ${message}`, optionalParams);
}

export function debug(tag: string, message?: any, ...optionalParams: any[]) {
  if (logLevel > LogLevel.DEBUG) {
    return;
  }
  console.debug(`${basicLogMessage()} ${tag}: ${message}`, optionalParams);
}

export function error(tag: string, message?: any, ...optionalParams: any[]) {
  console.error(`${basicLogMessage()} ${tag}: ${message}`, optionalParams);
}

export function warn(tag: string, message?: any, ...optionalParams: any[]) {
  if (logLevel > LogLevel.WARN) {
    return;
  }
  console.warn(`${basicLogMessage()} ${tag}: ${message}`, optionalParams);
}

export enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
}

let logLevel = LogLevel.DEBUG;

export function setLogLevel(level: LogLevel) {
  logLevel = level;
}
