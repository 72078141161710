import {getTime} from './utils/time';

/**
 * Object used to track elapsed time in streams
 */
export interface StreamTimer {
  /**
   * Signal that the stream has started progressing at a given timestamp
   * @param timestamp An event timestamp string
   */
  start: (timestamp: string) => void;
  /**
   * Signal that the stream has stopped progressing at a given timestamp
   * @param timestamp An event timestamp string
   */
  stop: (timestamp: string) => void;
  /**
   * Get the elapsed time in the stream at a given timestamp
   * @param timestamp An event timestamp string
   * @returns The stream's elapsed time in ms
   */
  elapsedTime: (timestamp: string) => number;
}

/**
 * Creates a {@link StreamTimer} to track elapsed time in streams
 * @returns A {@link StreamTimer} object
 */
export function newStreamTimer(): StreamTimer {
  /**
   * Time (in ms) at which time last started elapsing in this stream
   * NOTE: If undefined, it indicates that the stream is not currently in progress
   */
  let lastStartedTime: number | undefined;

  /**
   * The time (in ms) which has elapsed in this stream during previous start/stop events
   */
  let storedElapsedTime = 0;

  return {
    start: (timestamp: string) => {
      if (!lastStartedTime) {
        lastStartedTime = getTime(timestamp);
      }
    },
    stop: (timestamp: string) => {
      if (lastStartedTime) {
        const elapsedTime = getTime(timestamp) - lastStartedTime;
        storedElapsedTime += elapsedTime;
        lastStartedTime = undefined;
      }
    },
    elapsedTime: (timestamp: string): number => {
      if (lastStartedTime) {
        return getTime(timestamp) - lastStartedTime + storedElapsedTime;
      } else {
        return storedElapsedTime;
      }
    },
  };
}
