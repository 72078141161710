import {
  EventName,
  EventOptions,
  Media,
  PianoAnalytics,
} from 'piano-analytics-js';
import {EventType} from '../../eventType';
import {Event} from '../../event';
import {TagPlugin, TagPluginSessionInfo} from '../tagPlugin';
import {eventDataFromEvent} from './atInternetEvent';
import {debug, warn} from '../../utils/log';
import {newStreamWaypointEventThrottler} from '../../streamWaypointEventThrottler';
import {throttleEvent} from '../../eventThrottler';
import {PianoAnalyticsStorageManager} from './pianoAnalyticsStorageManager';
import {v4 as uuid} from 'uuid';

const TAG = 'ATInternetPlugin';

// Default collection domain for AT Internet if not provided
export const AT_INTERNET_DEFAULT_COLLECT_DOMAIN = 'https://atconnect.npo.nl';

/**
 * Threshold interval between waypoint events used in first minute of stream (in ms)
 */
export const AT_INTERNET_WAYPOINT_THRESHOLD_FIRST_MINUTE = 10_000;

/**
 * Threshold interval between waypoint events used after first minute of stream (in ms)
 */
export const AT_INTERNET_WAYPOINT_THRESHOLD_AFTER_FIRST_MINUTE = 60_000;

/**
 * AT Internet plugin
 *
 * Implementation of the {@link TagPlugin} interface used to send events to AT Internet via the
 * [piano-analytics-js sdk](https://developers.atinternet-solutions.com/piano-analytics/data-collection/sdks/javascript)
 */
export interface ATInternetPlugin extends TagPlugin {
  /**
   * Retrieve the configured `collectDomain`
   */
  getCollectDomain: () => string;
}

/**
 * Options object for {@link ATInternetPlugin}
 * @property collectDomain destination url to which events will be sent.
 */
export type ATInternetPluginOptions = {
  collectDomain: string;
};

const DEFAULT_AT_INTERNET_OPTIONS: ATInternetPluginOptions = {
  collectDomain: AT_INTERNET_DEFAULT_COLLECT_DOMAIN,
};

/**
 * Set of properties as returned by {@link ATInternetPlugin#getSessionInfo}.
 */
interface ATInternetSessionInfo {
  atVisitorId: string;
  avSessionId?: string;
}

/**
 * Instantiate new AT Internet plugin
 * @param options Optional configuration options (see: {@link ATInternetPluginOptions})
 * @returns A configured {@link ATInternetPlugin} object
 */
export function newATInternetPlugin(
  options?: Partial<ATInternetPluginOptions>
): ATInternetPlugin {
  const pluginOptions: ATInternetPluginOptions = {
    ...DEFAULT_AT_INTERNET_OPTIONS,
    ...options,
  };

  const eventThrottlers = [
    newStreamWaypointEventThrottler({
      0: AT_INTERNET_WAYPOINT_THRESHOLD_FIRST_MINUTE,
      60: AT_INTERNET_WAYPOINT_THRESHOLD_AFTER_FIRST_MINUTE,
    }),
  ];

  // Load piano-analytics-js module lazily so the PianoAnalytics constructor is not called during build
  // NOTE: If it is not lazily loaded, building the project in a NextJS site will fail due
  // to `window` being accessed during the build
  let _pianoAnalytics: PianoAnalytics | undefined;
  const getPianoAnalytics = (): PianoAnalytics => {
    if (!_pianoAnalytics) {
      const pa = require('piano-analytics-js').pianoAnalytics;

      // Storage would be chosen based on the availability of localStorage and in createStorage() function
      // The reason for this is that the piano-analytics-js library uses the cookies for storage by default
      // And some devices do not support cookies, so we need to use localStorage instead.
      // See issue https://innovattic.atlassian.net/browse/TOP-374 for more details
      const storageManager = PianoAnalyticsStorageManager.getInstance();

      // get a function that is bound to pa instance (i.e. `this` in the function refers to pa instance)
      const baseSetVisitorFunc = pa.setVisitorId.bind(pa);

      pa.setVisitorId = (visitorId: string) => {
        baseSetVisitorFunc(visitorId);
        storageManager.setVisitorId(visitorId);
      };

      if (!storageManager.visitorId) {
        const visitorId = pa.getVisitorId();
        // while the return type of getVisitorId() is string, it can return null.
        // Specifically, the id is lazy initialized, and if no value is passed to pa by setVisitorId(),
        // it will initialize the id whenever sendEvent() is called for the first time.
        if (!visitorId) {
          const visitorId = uuid();
          pa.setVisitorId(visitorId);
        }
      } else {
        const visitorId = storageManager.visitorId;
        pa.setVisitorId(visitorId);
      }
      pa.setConfigurations({
        collectDomain: pluginOptions.collectDomain,
      });
      _pianoAnalytics = pa;
    }

    return _pianoAnalytics!;
  };

  /**
   * Sends a 'standard' event to AT Internet as defined in the
   * [documentation](https://developers.atinternet-solutions.com/piano-analytics/data-collection/how-to-send-events/standard-events)
   * @param eventName An NPOTag {@link EventName}
   * @param event An NPOTag {@link Event} object
   */
  function sendEvent(eventName: EventName, event: Event) {
    getPianoAnalytics().sendEvent(eventName, eventDataFromEvent(event), {
      onBeforeSend: (_, eventData, next) => {
        debug(TAG, 'Sending event', eventName, eventData);
        next();
      },
    });
  }

  /**
   * Map of media objects keyed by stream id
   */
  const streamMediaById = new Map<string, Media>();

  // Can contain a preconfigured Piano media stream id, to use for the next-to-be-loaded stream.
  let mediaStreamIdToContinue: string | undefined = undefined;

  /**
   * Get {@link Media} for a given stream id
   *
   * @remarks
   * If the {@link Media} object doesn't exist it will be created
   */
  function getStreamMedia(streamId: string): Media {
    const existingStreamMedia = streamMediaById.get(streamId);
    if (existingStreamMedia) {
      return existingStreamMedia;
    }
    const pa = getPianoAnalytics();
    const media = new pa.avInsights.Media(
      undefined,
      undefined,
      mediaStreamIdToContinue
    );
    mediaStreamIdToContinue = undefined;
    streamMediaById.set(streamId, media);
    return media;
  }

  /**
   * Converts a seconds value to milliseconds
   * @param seconds time in seconds
   * @returns integer value in milliseconds
   */
  function toMillis(seconds: number): number {
    return Math.trunc(seconds * 1_000);
  }

  /**
   * Converts a seconds value to seconds
   * @param seconds time in seconds
   * @returns integer value in seconds
   */
  function toSecs(seconds: number): number {
    return Math.trunc(seconds);
  }

  /**
   * Sets the `site` configuration in the shared Piano Analytics instance (if required)
   * @param site value to set for Piano Analytics site parameter
   */
  function updateSiteConfiguration(site: number): void {
    const pa = getPianoAnalytics();
    if (pa.getConfiguration('site') === site) {
      return;
    }
    pa.setConfiguration('site', site);
  }

  /**
   * Sends stream events using the Piano Analytics AVInsights {@link Media} object
   * @param eventType the NPOTag {@link EventType} of this event
   * @param event the NPOTag {@link Event} defining the props for this event
   */
  function sendStreamEvent(eventType: EventType, event: Event): void {
    const media = getStreamMedia(event.stream_id!);
    // Cursor position in seconds for live streams, millis for VOD
    // (NOTE: this is because for live streams this value is seconds relative to epoch, so the int32 value cannot take millisecond precision)
    const cursorPosition = event.isLiveStream
      ? toSecs(event.stream_position!)
      : toMillis(event.stream_position!);
    const extraProps = eventDataFromEvent(event);
    const eventOptions: (eventName: string) => EventOptions = eventName => ({
      onBeforeSend: (_, eventData, next) => {
        debug(TAG, 'Sending stream event', eventName, eventData);
        next();
      },
    });
    switch (eventType) {
      case EventType.STREAM_WAYPOINT:
        media.heartbeat(
          cursorPosition,
          eventOptions('av.heartbeat'),
          extraProps
        );
        break;
      case EventType.STREAM_SEEK: {
        if (event.stream_seek_from === undefined) {
          warn(TAG, "A 'stream_seek_from' value is required for seek events.");
          return;
        }
        // Old cursor position in seconds for live streams, millis for VOD
        const oldCursorPosition = event.isLiveStream
          ? toSecs(event.stream_seek_from)
          : toMillis(event.stream_seek_from);
        media.seek(
          oldCursorPosition,
          cursorPosition,
          eventOptions(
            oldCursorPosition > cursorPosition ? 'av.backward' : 'av.forward'
          ),
          extraProps
        );
        break;
      }
      case EventType.STREAM_PAUSE:
        media.playbackPaused(
          cursorPosition,
          eventOptions('av.pause'),
          extraProps
        );
        break;
      case EventType.STREAM_RESUME:
        media.playbackResumed(
          cursorPosition,
          eventOptions('av.resume'),
          extraProps
        );
        break;
      case EventType.STREAM_COMPLETE:
      case EventType.STREAM_STOP:
        media.playbackStopped(
          cursorPosition,
          eventOptions('av.stop'),
          extraProps
        );
        break;
      case EventType.STREAM_BUFFERING:
        media.bufferStart(
          cursorPosition,
          eventOptions('av.rebuffer.start'),
          extraProps
        );
        break;
      case EventType.STREAM_BUFFERING_COMPLETE:
        media.rebufferHeartbeat(
          eventOptions('av.rebuffer.heartbeat'),
          extraProps
        );
        break;
      case EventType.STREAM_LOAD:
        media.bufferStart(
          cursorPosition,
          eventOptions('av.buffer.start'),
          extraProps
        );
        break;
      case EventType.STREAM_LOAD_COMPLETE:
        media.bufferHeartbeat(eventOptions('av.buffer.heartbeat'), extraProps);
        break;
      case EventType.STREAM_START:
        media.playbackStart(
          cursorPosition,
          eventOptions('av.start'),
          extraProps
        );
        break;
      case EventType.STREAM_FULLSCREEN:
        media.fullscreenOn(eventOptions('av.fullscreen.on'), extraProps);
        break;
      case EventType.STREAM_WINDOWED:
        media.fullscreenOff(eventOptions('av.fullscreen.off'), extraProps);
        break;
    }
  }

  return {
    submitEvent: (eventType: EventType, event: Event) => {
      if (throttleEvent(eventType, event, eventThrottlers)) {
        return;
      }

      updateSiteConfiguration(event.brand_id);

      switch (eventType) {
        case EventType.PAGE_VIEW:
          sendEvent('page.display', event);
          break;
        case EventType.CLICK:
          switch (event.click_type) {
            case 'navigation':
            case 'action':
            case 'exit':
            case 'download':
              sendEvent(`click.${event.click_type!}`, event);
              break;
            default:
              warn(
                TAG,
                `Click type '${event.click_type}' is not supported. Event will not be sent.`
              );
          }
          break;
        case EventType.STREAM_WAYPOINT:
        case EventType.STREAM_SEEK:
        case EventType.STREAM_PAUSE:
        case EventType.STREAM_RESUME:
        case EventType.STREAM_COMPLETE:
        case EventType.STREAM_BUFFERING:
        case EventType.STREAM_BUFFERING_COMPLETE:
        case EventType.STREAM_LOAD:
        case EventType.STREAM_LOAD_COMPLETE:
        case EventType.STREAM_START:
        case EventType.STREAM_FULLSCREEN:
        case EventType.STREAM_WINDOWED:
        case EventType.STREAM_STOP:
          sendStreamEvent(eventType, event);
          break;
      }
    },
    getCollectDomain: () =>
      getPianoAnalytics().getConfiguration('collectDomain') as string,

    getSessionInfo: () => {
      const pa = getPianoAnalytics();
      // if there's multiple streams, we'll just take the first
      // (https://innovattic.atlassian.net/browse/TOP-361)
      const activeMedia: Media | undefined = streamMediaById
        .values()
        .next().value;
      const props: ATInternetSessionInfo = {
        atVisitorId: pa.getVisitorId(),
        avSessionId: activeMedia && activeMedia.getSessionID(),
      };
      return {...props};
    },

    initializeFromSessionInfo: (data: TagPluginSessionInfo) => {
      const props = data as unknown as ATInternetSessionInfo;
      const pa = getPianoAnalytics();
      if (props.atVisitorId) {
        pa.setVisitorId(props.atVisitorId);
      } else {
        warn(
          TAG,
          'Configuration error: session info did not contain atVisitorId'
        );
      }
      mediaStreamIdToContinue = props.avSessionId;
    },
  };
}
