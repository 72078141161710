import {TagPlugin} from './tagPlugin';
import {Event} from '../event';
import {EventType} from '../eventType';

/**
 * Create an {@link TagPlugin} that write an event to the console
 */
export function newConsoleLoggerPlugin(): TagPlugin {
  /**
   * Create message string that describes an event
   * @param event is analytics event {@link Event}
   */
  function createMessage(eventType: EventType, event: Event) {
    const messageObject = {
      timestamp: new Date().toISOString(),
      eventType,
      event,
    };
    return JSON.stringify(messageObject);
  }

  return {
    submitEvent(eventType: EventType, event: Event) {
      console.log(createMessage(eventType, event));
    },
    initializeFromSessionInfo: () => {},
    getSessionInfo: () => ({}),
  };
}
