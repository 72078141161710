import {EventData, EventProperty} from 'piano-analytics-js';
import {Event} from '../../event';

/**
 * Object used to map {@link Event} properties in NPOTag
 * to {@link EventData} properties in AT Internet
 */
export const fieldMapping: {[key in keyof Event]?: string} = {
  brand_id: 'site',
  chapter_1: 'page_chapter1',
  chapter_2: 'page_chapter2',
  chapter_3: 'page_chapter3',
  page: 'page',
  platform: 'platform',
  content_context_id: 'contextID',
  user_subscription: 'user_type',
  query_context: 'ise_keyword',
  platform_version: 'platform_version',
  custom_label1: 'customlabel_1',
  custom_label2: 'customlabel_2',
  custom_label3: 'customlabel_3',
  custom_label4: 'customlabel_4',
  custom_label5: 'customlabel_5',
  error_code: 'error_code',
  program: 'programma',
  broadcasters: 'omroep',
  is_homepage: 'c_nmo_02',
  location: 'location',
  referrer: 'referrer',
  nmoid: 'cs_fpid',
  live_offset: 'live_offset_sec',
  live_offset_from: 'live_offset_from_sec',
  isLiveStream: 'av_broadcasting_type',
  stream_length: 'av_content_duration',
  stream_id: 'av_content_id',
  player_id: 'av_player',
  av_type: 'av_content_type',
  player_version: 'player_version',
  sko_player_version: 'av_player_version',
  sdk_version: 'sdk_version',
  click_name: 'click',
  click_type: 'event_name',
  click_chapter_1: 'click_chapter1',
  click_chapter_2: 'click_chapter2',
  click_chapter_3: 'click_chapter3',
};

/**
 * Converts an event property value to an event data prop value
 * @param eventProp name of the {@link Event} prop
 * @param value value in the {@link Event} object
 * @returns the corresponding event data prop value
 */
const transformValueForEventProp = (
  eventProp: keyof Event,
  value: string | number | boolean
): EventProperty => {
  switch (eventProp) {
    case 'is_homepage':
      // is_homepage is a boolean, but AT Internet expects 1 or 0
      return value ? 1 : 0;
    case 'isLiveStream':
      // isLiveStream is a boolean, but AT Internet expects a string
      return value ? 'live' : 'clip';
    case 'stream_length':
      // stream_length is in seconds, but AT Internet expects milliseconds
      return +value * 1000;
    default:
      return value;
  }
};

/**
 * Constructs a Piano Analytics {@link EventData} object from an NPOTag {@link Event}
 * @param event An {@link Event} object
 * @returns An {@link EventData} object containing only the defined fields from the Event
 */
export const eventDataFromEvent = (event: Event): EventData => {
  const eventData: EventData = {};
  Object.keys(event).forEach(key => {
    const eventProp = key as keyof Event;
    const eventDataProp = fieldMapping[eventProp];
    const value = event[eventProp];
    if (value !== undefined && eventDataProp !== undefined) {
      eventData[eventDataProp] = transformValueForEventProp(eventProp, value);
    }
  });
  return eventData;
};
