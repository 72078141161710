import murmurhash3js from 'murmurhash3js';
import {utf8} from '../../utils/encoding';

export type Multimap = {[key: string]: string[]};

/**
 * Produce a checksum for a multimap.
 *
 * The checksum is a hash of a canonical string derived
 * from the multimap. The multimap format is an object
 * whose values are arrays of strings.
 *
 * @param multimap The {@link Multimap} whose contents should be checksummed.
 * @return a number that represents a checksum of the multimap.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const calculateCheckSum = (multimap: Multimap): number => {
  /*
   * Build up a canonical representation of the query parameters. The canonical order is:
   *  1) Sort the query parameters by key, preserving multiple values (and their order).
   *  2) Build up a string. For each parameter:
   *     a) Append the parameter name, followed by a '='.
   *     b) Append each value of the parameter, followed by a ','.
   *     c) Append a ';'.
   *  This is designed to be unambiguous in the face of many edge cases.
   */
  const keys = [];
  for (const k in multimap) {
    if (Object.prototype.hasOwnProperty.call(multimap, k)) {
      keys.push(k);
    }
  }
  keys.sort();
  let canonicalString = '';
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i],
      values = multimap[key];
    canonicalString += key;
    canonicalString += '=';
    for (let j = 0; j < values.length; ++j) {
      canonicalString += values[j];
      canonicalString += ',';
    }
    canonicalString += ';';
  }
  const canonicalBytes = utf8(canonicalString);
  const result = murmurhash3js.x86.hash32(canonicalBytes);
  return result;
};
