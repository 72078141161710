/**
 * Sleep for a given interval (in ms)
 * @param ms number of milliseconds to sleep
 * @returns a promise which resolves after the specified interval
 */
export function sleep(ms: number): Promise<void> {
  return new Promise(r => setTimeout(r, ms));
}

/**
 * Get time in milliseconds from a timestamp string
 * @param timestamp an ISO timestamp string
 * @returns the time of the timestamp in ms
 */
export function getTime(timestamp: string): number {
  return new Date(timestamp).getTime();
}
