import {v4 as uuidv4} from 'uuid';

/**
 * Time of module initialization.
 * This is used to ensure consistent timestamps when multiple identifiers are generated.
 * The timestamp is encoded to a string using base 36.
 */
export const SCRIPT_LOAD_TIME = Date.now().toString(36);

/**
 * Generate a govolte-formatted identifier
 *
 * @returns A globally unique identifier
 *
 * @remarks
 * A govolte identifier is of the format:
 * ```
 * <id_version_number>:<timestamp_of_session_start_in_milliseconds_since_epoch>:<32_char_alphanumerics><'!' if no secure crypto library was available>
 * ```
 * The current version of the id generator is v1. This differs from v0 in the following ways:
 * - id_version_number is now 1 (previously 0)
 * - 32_char_alphanumeric is now a uuid with the dashes removed (previously it was custom generated using a pseudo-random byte array)
 * - the '!' suffix is never used because the uuidv4() function relies on the web crypto api being available
 *
 * The timestamp_of_session_start_in_milliseconds_since_epoch field is the same as the previous version,
 * i.e. the timestamp of the script load time encoded to a string using base 36.
 *
 * @throws {@link Error} -
 * An exception is thrown by uuidv4 if the crypto api is not available in this context
 */
export function generateGovolteId(): string {
  const digits = uuidv4().replace(/-/g, '');
  return `1:${SCRIPT_LOAD_TIME}:${digits}`;
}

/**
 * Generates a random DAMID
 * @returns A random string
 *
 * @remarks
 * The NMO DAM (Nederlands Media Onderzoek - Digital Audience Measurements) requires
 * a unique identifier for each session (in this context, a browser session) which
 * is in the form:
 * <currentDateTimeInMillisec>_<random_integer_8_digits>
 */
export function generateNmoId(): string {
  return [new Date().getTime(), Math.random().toFixed(8).substring(2, 10)].join(
    '_'
  );
}
