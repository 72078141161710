import {EventThrottler} from './eventThrottler';
import {EventType} from './eventType';
import {Event} from './event';
import {newStreamTimer, StreamTimer} from './streamTimer';

/**
 * Throttles {@link EventType.STREAM_WAYPOINT} events
 * @param eventType {@link EventType} of the event
 * @param event {@link Event} object to check
 * @returns `true` if event should be throttled, `false` otherwise
 * @remarks
 * This throttler will throttle {@link EventType.STREAM_WAYPOINT} events
 * based on the elapsed time in the stream. The threshold can either be a
 * simple constant value, or it can be an object keyed by elapsed time and
 * with values representing the threshold to be applied from that point in
 * the stream.
 * @example
 * // Simple constant threshold
 * const throttler = newStreamWaypointEventThrottler(1000);
 * // Thresholds keyed by elapsed time
 * const throttler = newStreamWaypointEventThrottler({
 *  0: 1000,
 *  10000: 5000,
 *  30000: 10000,
 * });
 * ```
 */
export function newStreamWaypointEventThrottler(
  threshold: number | {[key: number]: number}
): EventThrottler {
  /**
   * Map of {@link StreamTimer} objects keyed by stream id
   */
  const streamTimerById = new Map<string, StreamTimer>();
  /**
   * Map of last event times keyed by stream id
   * @remark
   * Last event time is the elapsed time value at the point the last event
   * was sent for this stream
   */
  const lastEventTimeById = new Map<string, number>();

  /**
   * Get {@link StreamTimer} for a given stream id
   *
   * @remarks
   * If the {@link StreamTimer} object doesn't exist it will be created
   */
  function getStreamTimer(streamId: string): StreamTimer {
    const existingStreamTimer = streamTimerById.get(streamId);
    if (existingStreamTimer) {
      return existingStreamTimer;
    }
    const streamTimer = newStreamTimer();
    streamTimerById.set(streamId, streamTimer);
    return streamTimer;
  }

  /**
   * Applies the configured threshold for the given elapsed time and last event time
   * @param elapsedTime current elapsed time in the stream
   * @param lastEventTime last event time for this stream
   * @returns `true` if waypoint event should be throttled, `false` otherwise
   *
   * @remarks
   * The threshold can either be a simple constant value, or it can be
   * an object keyed by elapsed time and with values representing the
   * threshold to be applied from that point in the stream.
   * For example, the threshold object `{0: 10, 60: 30}` would throttle
   * the waypoints every 10 seconds in the first minute, and every minute
   * from then on.
   * The threshold is applied within a configured window of time, so if the elapsed
   * time has crossed the boundary into another threshold window, the threshold
   * will be compared to the elapsed time since the last threshold window.
   * For example, if the threshold is `{0: 10, 60: 30}`, and the waypoint event is
   * triggered every second, the events will be sent at 0, 10, 20, 30, 40, 50, 60, 90, 120, etc.
   */
  function applyThreshold(elapsedTime: number, lastEventTime: number): boolean {
    if (typeof threshold !== 'object') {
      // If threshold is a simple number, just return compare to the elapsed time since the last event
      return elapsedTime - lastEventTime < threshold;
    }
    // Sort the threshold keys in ascending numerical order
    const sortedKeys = Object.keys(threshold)
      .map(key => parseInt(key))
      .sort((a, b) => a - b);
    let thresholdKey = 0;
    const lastEventTimeSec = Math.trunc(lastEventTime / 1_000);
    for (const key of sortedKeys) {
      if (lastEventTimeSec < key) {
        break;
      }
      thresholdKey = key;
    }
    return elapsedTime - lastEventTime < threshold[thresholdKey];
  }

  /**
   * Checks if a {@link EventType.STREAM_WAYPOINT} event should be throttled
   * @param timestamp the client timestamp of the event being checked
   * @param streamTimer the {@link StreamTimer} for this stream
   * @param lastEventTime time (in ms of elapsed time) when the last event was sent for this stream
   * @returns `true` if waypoint event should be throttled, `false` otherwise
   */
  function shouldThrottleStreamWaypointEvent(
    timestamp: string,
    streamTimer: StreamTimer,
    lastEventTime: number | undefined
  ): boolean {
    // If there is no previous event for this stream, use 0 as the reference time
    const referenceTime = lastEventTime || 0;

    // Get the elapsed time in this stream
    const elapsedTime = streamTimer.elapsedTime(timestamp);

    // If the time between this event and the last is lower than the threshold, we should throttle the event
    return applyThreshold(elapsedTime, referenceTime);
  }

  return {
    shouldThrottleEvent: (eventType: EventType, event: Event) => {
      if (eventType !== EventType.STREAM_WAYPOINT) {
        return false;
      }
      return shouldThrottleStreamWaypointEvent(
        event.client_timestamp,
        getStreamTimer(event.stream_id!),
        lastEventTimeById.get(event.stream_id!)
      );
    },
    onSendEvent: (eventType: EventType, event: Event) => {
      switch (eventType) {
        // Start the stream timer on start, buffering complete and resume events
        case EventType.STREAM_START:
        case EventType.STREAM_BUFFERING_COMPLETE:
        case EventType.STREAM_RESUME:
          getStreamTimer(event.stream_id!).start(event.client_timestamp);
          break;
        // Stop the stream timer on buffering and pause
        case EventType.STREAM_BUFFERING:
        case EventType.STREAM_PAUSE:
          getStreamTimer(event.stream_id!).stop(event.client_timestamp);
          break;
        // Update the last event time on waypoint events
        case EventType.STREAM_WAYPOINT:
          lastEventTimeById.set(
            event.stream_id!,
            getStreamTimer(event.stream_id!).elapsedTime(event.client_timestamp)
          );
          break;
        // Remove the timer and last event time to reset throttling on complete and stop events
        case EventType.STREAM_COMPLETE:
        case EventType.STREAM_STOP:
          streamTimerById.delete(event.stream_id!);
          lastEventTimeById.delete(event.stream_id!);
          break;
      }
    },
  };
}
