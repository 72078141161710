import {h32} from 'xxhashjs';

export interface TestCondition {
  /**
   * The name of the condition that can be randomly assigned for an identifier
   */
  name: string;
  /**
   * Probability of an identifier being assigned to this group. Should sum to one for all conditions.
   */
  weight: number;
}

export interface ABSetup {
  /**
   * Name of the experiment. Used to keep assignments stable so this should not change while running the experiment.
   */
  experiment_name: string;
  /**
   * The conditions that are part of this experiment. Their weights should sum to one.
   */
  conditions: TestCondition[];
}

/**
 *
 * @param identifier any identifier used to maintain consistent assignment within an experiment
 * @param setup name and conditions for the experiment
 * @returns the assignment returns the 'name' of one of the conditions OR 'default' when assignment was not possible.
 */
export const ABAssign = (identifier: string, setup: ABSetup): string => {
  // use conditions and weights so that assignment is random even when the same
  // experiment name is used with a new condition or different distributions
  let conditionsConcat = '';
  setup.conditions.forEach(c => (conditionsConcat += c.name + `${c.weight}`));

  const key = identifier + setup.experiment_name + conditionsConcat;

  // sanity check that weights sum to one
  let totalWeight = 0;
  const names = new Set<string>();
  setup.conditions.forEach(v => {
    if (v.weight < 0.0 || v.weight > 1.0){
      console.warn(setup.experiment_name + ' conditions should have weights between 0.0 and 1.0')
    }
    totalWeight += v.weight;
    names.add(v.name);
  });

  const tolerance = 200*Number.EPSILON;
  const differenceBiggerThanTolerance = Math.abs(totalWeight - 1) > tolerance;
  if (differenceBiggerThanTolerance) {
    console.warn(
      setup.experiment_name + ' weights do not sum to one! total= ' + totalWeight
    );
    return 'default';
  }
  if (names.size !== setup.conditions.length) {
    console.warn(setup.experiment_name + ' condition names are not unique!');
    return 'default';
  }

  return assign(key, setup.conditions);
};

/**
 *
 * @param key the key which serves as the 'seed' for random assignment
 * @param conditions the conditions in which to assign the key
 * @returns either 'default' if something went wrong or the condition name the key was assigned to
 */
const assign = (key: string, conditions: TestCondition[]): string => {
  // randomly place the key in the value space through hashing it,
  // which creates a random value between 0 and maxVal that
  // is always the same for this key

  const ab  = h32().update(key).digest()


  // force the sha1 results in a known-size space [0,maxVal)
  // we cast the values to Uint32 instead of BigInt because of old browser support
  const maxVal = Number(4294967295);
  const value = Number(ab);

  // divide the value-space into groups
  interface ConditionLimit {
    name: string;
    lower: number;
    upper: number;
  }

  const limits: ConditionLimit[] = [];
  let previousLimit = 0;
  conditions.forEach(c => {
    const limit: ConditionLimit = {
      name: c.name,
      lower: previousLimit,
      upper: previousLimit + c.weight * maxVal,
    };
    limits.push(limit);
    previousLimit = limit.upper;
  });

  // check which group the value of the key is in
  let assigned = 'default';
  limits.forEach(l => {
    if (l.lower <= value && l.upper >= value) {
      assigned = l.name;
    }
  });
  return assigned;
};
