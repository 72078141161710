import {EventType} from './eventType';
import {generateGovolteId} from './idGenerator';
import {submitEvent} from './npoTag';
import {PageTracker} from './pageTracker';

export const PAGE_HOME = 'home';
/**
 * Provides tracking context to the {@link RecommendationTracker}.
 *
 * @remarks
 * This context object contains properties shared by all impressions in a recommendation panel.
 */
export interface RecommendationContext {
  /**
   * Identifier for all events sent in this context (generated internally)
   */
  readonly offer_id: string;
  /**
   * Identifier of this panel (e.g. '23889851-fec5-4106-8501-122f1e233686')
   */
  readonly panel_id: string;
  /**
   * Total amount of items in this panel (e.g. 9)
   */
  readonly total_offers: number;
  /**
   * The UX-format of the panel (e.g. 'lane' , '5grid', ...)
   */
  readonly panel_format: string;
  /**
   * The position of the panel on the page, can be absent for
   * panels such as a search-results pop-over (e.g. 0)
   */
  readonly panel_position?: number;
}

export interface RecommendationEventProps {
  /**
   * Recommendation algorithm used (e.g. 'ps-implicit-v0')
   */
  recommender: string;
  /**
   * The ID of the content being offered (e.g. 'VPWON_123')
   */
  target_id: string;
  /**
   * The position of the offer within the panel (2)
   */
  offer_index: number;
}

/**
 * RecommendationTracker interface used to send events to analytics plugins
 */
export interface RecommendationTracker {
  readonly pageTracker: PageTracker;
  readonly recommendationContext: RecommendationContext;

  /**
   * Sends an {@link EventType.OFFER} event
   * @param props see {@link RecommendationEventProps}
   *
   * @remarks
   * This should be sent when a user is shown an impression
   */
  offer(props: Readonly<RecommendationEventProps>): void;
  /**
   * Sends an {@link EventType.CHOICE} event
   * @param props see {@link RecommendationEventProps}
   *
   * @remarks
   * Used to indicate a user has interacted with an impression
   */
  choice(props: Readonly<RecommendationEventProps>): void;
}

/**
 * Creates a new {@link RecommendationTracker} object with the given context
 *
 * @param pageTracker The {@link PageTracker} which is the parent of this recommendation panel
 * @param recommendationContext A {@link RecommendationContext} object
 * @returns A {@link RecommendationTracker} object to log impression events
 */
export function newRecommendationTracker(
  pageTracker: PageTracker,
  recommendationContext: Omit<RecommendationContext, 'offer_id'>
): RecommendationTracker {
  const ctx: RecommendationContext = {
    ...recommendationContext,
    offer_id: generateGovolteId(),
  };
  return {
    pageTracker,
    recommendationContext: ctx,
    offer: (props: RecommendationEventProps) =>
      submitEvent(
        EventType.OFFER,
        pageTracker.npoTag,
        pageTracker.pageContext,
        {...ctx, ...props}
      ),
    choice: (props: RecommendationEventProps) =>
      submitEvent(
        EventType.CHOICE,
        pageTracker.npoTag,
        pageTracker.pageContext,
        {...ctx, ...props}
      ),
  };
}
