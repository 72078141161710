import {createStorage, StorageInterface} from '../../storage';

class PianoAnalyticsStorageManager {
  private static instance: PianoAnalyticsStorageManager;

  private storage: StorageInterface;

  private static PIANO_VISITOR_ID_STORAGE_KEY = 'PianoVisitorID';

  private expiryTimeInDays = 395;

  private constructor() {
    this.storage = createStorage();
  }

  public get visitorId(): string | undefined {
    return this.storage.get(
      PianoAnalyticsStorageManager.PIANO_VISITOR_ID_STORAGE_KEY
    );
  }

  public setVisitorId(visitorId: string) {
    this.storage.set(
      PianoAnalyticsStorageManager.PIANO_VISITOR_ID_STORAGE_KEY,
      visitorId,
      {
        expires: this.expiryTimeInDays,
      }
    );
  }

  public static getInstance(): PianoAnalyticsStorageManager {
    if (!PianoAnalyticsStorageManager.instance) {
      PianoAnalyticsStorageManager.instance =
        new PianoAnalyticsStorageManager();
    }
    return PianoAnalyticsStorageManager.instance;
  }
}

export {PianoAnalyticsStorageManager};
