import {EventType} from './eventType';
import {submitEvent} from './npoTag';
import {PageTracker} from './pageTracker';

/**
 * Type used to distinguish audio and video streams
 */
export type AVType = 'audio' | 'video';

/**
 * Provides tracking context to the {@link StreamTracker}
 *
 * @remarks
 * This context object contains properties shared by all events in a stream,
 * such as an embedded video
 */
export interface StreamContext {
  /**
   * The length of the stream in seconds (e.g. 210)
   */
  stream_length: number;
  /**
   * The ID of the stream
   */
  stream_id: string;
  /**
   * The ID of the player
   */
  player_id: string;
  /**
   * The type of stream (see: {@link AVType})
   */
  av_type: AVType;
  /**
   * The version of the player
   */
  player_version: string;
  /**
   * The SKO version of the player
   */
  sko_player_version: string;
}

/**
 * Event method properties common to all stream events
 */
export interface StreamEventProps {
  stream_position: number;
}

/**
 * Seek event method properties
 */
export interface SeekEventProps extends StreamEventProps {
  seek_from: number;
}

/**
 * StreamTracker interface used to send stream events to analytics plugins
 */
export interface StreamTracker {
  readonly pageTracker: PageTracker;
  readonly streamContext: StreamContext;

  /**
   * Log a streamWaypoint event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  time(props: Readonly<StreamEventProps>): void;

  /**
   * Log a streamSeek event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   * @param props.seek_from Stream position in seconds at the beginning of the seek action
   */
  seek(props: Readonly<SeekEventProps>): void;

  /**
   * Log a streamPause event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  pause(props: Readonly<StreamEventProps>): void;

  /**
   * Log a streamResume event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  resume(props: Readonly<StreamEventProps>): void;

  /**
   * Log a streamComplete event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  complete(props: Readonly<StreamEventProps>): void;

  /**
   * Log a streamBuffering event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  buffering(props: Readonly<StreamEventProps>): void;

  /**
   * Log a streamBufferingComplete event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  buffering_complete(props: Readonly<StreamEventProps>): void;

  /**
   * Log a streamLoad event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  load(props: Readonly<StreamEventProps>): void;

  /**
   * Log a streamLoadComplete event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  load_complete(props: Readonly<StreamEventProps>): void;

  /**
   * Log a streamStart event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  start(props: Readonly<StreamEventProps>): void;

  /**
   * Log a streamFullscreen event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  fullscreen(props: Readonly<StreamEventProps>): void;

  /**
   * Log a streamWindowed event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  windowed(props: Readonly<StreamEventProps>): void;

  /**
   * Log a streamStop event for the current {@link StreamContext}
   *
   * @param props.stream_position Stream position in seconds (or live offset if stream is live)
   */
  stop(props: Readonly<StreamEventProps>): void;
}

/**
 * Optional configuration parameters which can be passed to the {@link StreamTracker} on initialisation
 */
interface StreamTrackerOptions {
  isLive?: boolean;
}

/**
 * Subset of {@link EventType} for all events which relate to streams
 */
type StreamEventType =
  | EventType.STREAM_WAYPOINT
  | EventType.STREAM_SEEK
  | EventType.STREAM_PAUSE
  | EventType.STREAM_RESUME
  | EventType.STREAM_COMPLETE
  | EventType.STREAM_BUFFERING
  | EventType.STREAM_BUFFERING_COMPLETE
  | EventType.STREAM_LOAD
  | EventType.STREAM_LOAD_COMPLETE
  | EventType.STREAM_START
  | EventType.STREAM_FULLSCREEN
  | EventType.STREAM_WINDOWED
  | EventType.STREAM_STOP;

/**
 * Creates a new {@link StreamTracker} object with the given context
 *
 * @param pageTracker The {@link PageTracker} which is the parent of this stream
 * @param streamContext {@link StreamContext} object containing all required props
 * @param options An optional {@link StreamTrackerOptions} object which can be used to pass configuration options.
 * @returns A {@link StreamTracker} object to log stream events
 *
 * @remarks
 * If `options.isLive` is set to true, the `stream_position` passed as an event argument should be
 * the offset of the stream from the live position.
 */
export function newStreamTracker(
  pageTracker: PageTracker,
  streamContext: StreamContext,
  options?: StreamTrackerOptions
): StreamTracker {
  /**
   * Helper function to submit stream events with the required context and arguments
   * @param eventType {@link EventType} of this stream event
   * @param props event properties to be converted to {@link EventArguments}
   *
   * @remarks
   * If `isLive` is set to true, the `stream_position` property will be assigned
   * to the `live_offset` event argument.
   */
  function submitStreamEvent(
    eventType: StreamEventType,
    props: Readonly<StreamEventProps> & Partial<Readonly<SeekEventProps>>
  ) {
    let stream_position = props.stream_position;
    let live_offset = undefined;
    let stream_seek_from = props.seek_from;
    let live_offset_from = undefined;
    if (options?.isLive) {
      // For live streams we need to change the stream position (i.e. offset from live) into a time since epoch
      // This is due to a limitation in piano analytics where events are skipped if the cursor position is the same as the previous event
      // In live streams, the offset is often the same as the previous event, so we need to use a timestamp instead
      const timestampSec = Date.now() / 1000;
      // For live streams, the stream_position prop is the offset from the live position (i.e. negative), hence we add it to the current timestamp
      stream_position = timestampSec + props.stream_position;
      live_offset = props.stream_position;
      if (props.seek_from !== undefined) {
        stream_seek_from = timestampSec + props.seek_from;
        live_offset_from = props.seek_from;
      }
    }
    submitEvent(eventType, pageTracker.npoTag, pageTracker.pageContext, {
      ...streamContext,
      stream_position,
      live_offset,
      stream_seek_from,
      live_offset_from,
      isLiveStream: options?.isLive ?? false,
    });
  }

  return {
    pageTracker,
    streamContext,
    time: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_WAYPOINT, props);
    },
    seek: (props: Readonly<SeekEventProps>) => {
      submitStreamEvent(EventType.STREAM_SEEK, props);
    },
    pause: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_PAUSE, props);
    },
    resume: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_RESUME, props);
    },
    complete: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_COMPLETE, props);
    },
    buffering: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_BUFFERING, props);
    },
    buffering_complete: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_BUFFERING_COMPLETE, props);
    },
    load: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_LOAD, props);
    },
    load_complete: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_LOAD_COMPLETE, props);
    },
    start: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_START, props);
    },
    fullscreen: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_FULLSCREEN, props);
    },
    windowed: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_WINDOWED, props);
    },
    stop: (props: Readonly<StreamEventProps>) => {
      submitStreamEvent(EventType.STREAM_STOP, props);
    },
  };
}
