export const base36 = (n: number) => n.toString(36);

/**
 * UTF-8 encode a string.
 *
 * @param {string} s  The string to UTF-8 encode.
 * @return {string} The UTF-8 encoded string, as a string whereby every character corresponds
 *    to a byte of the UTF-8 encoding of the original string.
 */
export const utf8 = function (s: string): string {
  return unescape(encodeURIComponent(s));
};
