/**
 * Queue structure. Inspired by https://github.com/datastructures-js/queue/blob/master/src/queue.js
 */
export class Queue<T> {
  private items: T[] = [];
  private offset = 0;

  /**
   * Add one or multiple elements to the queue
   * @param elements
   */
  enqueue(...elements: T[]) {
    return this.items.push(...elements);
  }

  /**
   * Removes the first element from queue and returns that removed element.
   * This method changes the length of the array.
   */
  dequeue(): T | null {
    if (this.isEmpty()) {
      return null;
    }

    const first = this.peek();
    this.offset += 1;

    if (this.offset * 2 < this.items.length) {
      return first;
    }

    // Only remove dequeued elements when reaching half size to
    // decrease latency of shifting elements.
    this.items = this.items.slice(this.offset);
    this.offset = 0;
    return first;
  }

  /**
   * Return queue size
   */
  size() {
    return this.items.length - this.offset;
  }

  /**
   * Return true if no element exists in queue
   */
  isEmpty() {
    return this.size() === 0;
  }

  /**
   * View the first item in queue without removing it
   */
  peek(): T | null {
    if (this.isEmpty()) {
      return null;
    }
    return this.items[this.offset];
  }

  /**
   * Swap the element at the top queue with new {@param item}
   * @param item
   */
  swapTopElement(item: T) {
    this.items[this.offset] = item;
  }

  getAllItems(): T[] {
    return this.items.slice(this.offset);
  }

  /**
   * Remove all elements from queue
   */
  clear() {
    this.items = [];
    this.offset = 0;
  }

  getOffset(): number {
    return this.offset;
  }
}
